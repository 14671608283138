import * as React from 'react';
import { Link, graphql } from 'gatsby';
import { Box, Container, Flex, Stack, Icon } from '@chakra-ui/react';
import { StaticImage } from 'gatsby-plugin-image';
import parse from 'html-react-parser';
import { FaEnvelope, FaFacebookF, FaLinkedinIn } from 'react-icons/fa';
import CustomButton from '../components/custom-button';
import SEO from '../components/seo';
import Layout from '../components/layout';
import '../styles/case-study.scss';

const CaseStudy = (props) => {
  // eslint-disable-next-line react/destructuring-assignment
  const post = props.data.wpCaseStudy;
  return (
    <Layout>
      <SEO
        title="Enhanced Efficiency Fertilizers | Crystal Green | Ostara"
        description="Optimize plant nutrition by increasing uptake and reducing runoff with enhanced efficiency fertilizers designed for today's data-driven and environmentally aware growers."
        image="https://pacemediafiles.s3.us-west-2.amazonaws.com/ostara/ostara_logo.png"
        keywords="Enhanced Efficiency Fertilizers"
      />
      <main className="case-study-page">
        <Box w="100%" p={4} className="case-study-hero-container">
          <Container maxW={1400}>
            <Box
              display="flex"
              flexDirection={['column', 'column', 'column', 'row']}
              justifyContent="space-between"
              paddingTop={[0, 0, 10, 50]}
            >
              <Box
                marginTop={[5, 5, 5, 0]}
                w={['100%', '100%', '100%', '46.5%']}
              >
                <Stack
                  className="case-study-hero-container--title"
                  maxW="50rem"
                  spacing={6}
                >
                  <Link to="/case-studies">Back</Link>
                  <h2>{post.title}</h2>
                  {parse(post.excerpt)}
                  <div className="case-study-tags">
                    <p>Crystal Green Fertilizers</p>
                  </div>
                </Stack>
              </Box>
              <Stack
                spacing={[4, 6, 4, 6, 8]}
                w={['100%', '100%', '100%', '46.5%']}
              >
                <Box className="case-study-hero-img-container">
                  <StaticImage
                    className="hero-img"
                    placeholder="transparent"
                    src="../images/case-study-img.png"
                    alt="case-study-thumbnail"
                  />
                </Box>
              </Stack>
            </Box>
          </Container>
        </Box>
        <Box w="100%" className="case-study-body-container">
          <Flex direction="column">
            <Container
              marginTop={[10, 10, 10, 100]}
              marginBottom={[10, 10, 10, 100]}
              maxW="container.lg"
            >
              {parse(post.content)}
            </Container>
          </Flex>
        </Box>

        <Box w="100%" className="case-study-footer-container">
          <Flex direction="column">
            <Container maxW="container.lg">
              <hr />
              <h5>COMPANY INFO</h5>
              <p>
                Founded in 2005
                <br />
                35 full time staff
                <br />
                HQ Vancouver, Canada
                <br />
                23 commercial installations worldwide
              </p>
              <hr />
              <div className="case-study-footer-container--media">
                <div className="share-container">
                  Share: <Icon as={FaFacebookF} />
                  <Icon as={FaLinkedinIn} />
                  <Icon as={FaEnvelope} />
                </div>
              </div>
            </Container>
          </Flex>
        </Box>
        <Box w="100%" className="case-study-body-container3">
          <Container maxW={1400} marginTop={20}>
            <Flex
              className="case-study-body-container3--heading"
              alignItems="center"
              justifyContent="space-between"
            >
              <h2>Related Case Studies</h2>
              <CustomButton to="/case-studies" maxW={230}>
                VIEW ALL
              </CustomButton>
            </Flex>
            <Flex justifyContent="space-between" flexWrap="wrap">
              <div className="related-items">
                <StaticImage
                  src="../images/article-sample1.png"
                  alt="Crop Data Overlay img"
                  className="articles-thumbnail"
                />
                <h3>Title</h3>
                <p>
                  Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit, Sed
                  Do Eiusmod Tempor Incididunt Ut Labore Et Dolore Magna Aliqua.
                </p>
                <Link to="/articles">Read</Link>
              </div>
              <div className="related-items">
                <StaticImage
                  src="../images/article-sample2.png"
                  alt="Crop Data Overlay img"
                  className="articles-thumbnail"
                />
                <h3>Title</h3>
                <p>
                  Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit, Sed
                  Do Eiusmod Tempor Incididunt Ut Labore Et Dolore Magna Aliqua.
                </p>
                <Link to="/articles">Read</Link>
              </div>
              <div className="related-items">
                <StaticImage
                  src="../images/article-sample3.png"
                  alt="Crop Data Overlay img"
                  className="articles-thumbnail"
                />
                <h3>Title</h3>
                <p>
                  Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit, Sed
                  Do Eiusmod Tempor Incididunt Ut Labore Et Dolore Magna Aliqua.
                </p>
                <Link to="/articles">Read</Link>
              </div>
            </Flex>
          </Container>
        </Box>
      </main>
    </Layout>
  );
};

export default CaseStudy;
export const postQuery = graphql`
  query ($id: String) {
    wpCaseStudy(id: { eq: $id }) {
      title
      content
      slug
      excerpt
      date(formatString: "DD MMMM YYYY")
      featuredImage {
        node {
          sourceUrl
        }
      }
    }
  }
`;
